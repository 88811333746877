import { IconButton, Tooltip } from '@material-ui/core'
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { ReactComponent as DownloadIcon } from 'src/assets/images/svgs/download-icon.svg'
import { APIContext, getDatasetURI } from 'src/contexts/api'
import { WorkspaceContext } from 'src/contexts/workspace'
import IDataset from 'src/models/IDataset'
import { logError } from 'src/utils/Error'


const DownloadDataset = (props: PropsWithChildren<{
  alias?: string
  dataset?: IDataset
  tooltip?: string
}>): JSX.Element => {

  const { workspace } = useContext(WorkspaceContext)
  const { catalogAPI: api }: { catalogAPI: AxiosInstance } = useContext(APIContext)

  const [dataset, setDataset] = useState<IDataset | undefined>(props.dataset)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (dataset)
      return

    api.get(getDatasetURI(workspace.id, props.alias))
      .then((r: AxiosResponse<IDataset>) => setDataset(r.data))
      .catch(logError)
  }, [])

  return <>
    {
      dataset &&
      <Tooltip
        arrow
        title={props.tooltip || `Download ${dataset.title || dataset.alias}`}
        enterDelay={700}
        enterNextDelay={700}>
        <IconButton
          disabled={disabled}
          onClick={async () => {
            setDisabled(true)

            const [data, extension] = await (async () => {
              if (dataset.rawData)
                return [dataset.rawData, '.txt']

              if (!dataset._links.data)
                return [dataset.description, '.md']


              const csv = await api.get(dataset._links.data.href, {
                headers: {
                  Accept: 'text/csv'
                },
                errorHandler: false,
              } as AxiosRequestConfig)
                .then((r: AxiosResponse<string>) => r.data)
                .catch(logError)

              return [csv, '.csv']
            })()

            if (!data)
              return

            const link = document.createElement('a')
            link.href = URL.createObjectURL(new Blob([data]))
            link.target = '_blank'
            link.download = dataset.alias! + extension

            link.click()
            link.remove()

            setDisabled(false)
          }}>
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    }
  </>

}

export default DownloadDataset
