import { InputAdornment, Snackbar, TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Autocomplete } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import * as assert from 'assert-plus';
import { AxiosInstance } from 'axios';
import React, { FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import useStyles from 'src/assets/css/components/FormStyles';
import 'src/assets/css/components/workspaceForm.css';
import questionMark from 'src/assets/images/questionMark.png';
import ConfirmDialog from 'src/components/ConfirmDialog';
import CustomAccordion from 'src/components/CustomAccordion';
import Spinner from 'src/components/Spinner';
import { APIContext } from 'src/contexts/api';
import { WindowContext } from 'src/contexts/window';
import useWorkspaceForm from 'src/hooks/useWorkspaceForm';
import IError from 'src/models/IError';
import IValidationError from 'src/models/IValidationError';
import { IWorkspaceFormProps } from 'src/models/components/IWorkspaceForm';
import { AppEnv, AppFeatures, isFeatureEnabled } from '../utils/appenv';
import copyIcon from './../assets/images/copyIcon.png';
import WorkspaceImage from './WorkspaceImage';

const WorkspaceForm: FunctionComponent<IWorkspaceFormProps> = (
    props: PropsWithChildren<IWorkspaceFormProps>
) => {
    const classes = useStyles();
    const { size } = useContext(WindowContext);
    const { catalogAPI } = useContext(APIContext);
    const api = catalogAPI as AxiosInstance;
    assert.func(api, 'APIContext must be initialised before this component is used')
    const { workspaceId, workspace, message, savedCallback, deletedCallback, handleMessageClosed, copyText, helpButtonClicked } = props;
    const hook = useWorkspaceForm(api, savedCallback, deletedCallback)
    const [isDeployDisabled, setDeployDisabled] = useState(false)

    let validationErrors = hook.validationErrors ? hook.validationErrors : []
    const nameError = validationErrors.find((obj: any) => {
                      return obj.field === `name`;
                  });
    validationErrors = validationErrors.filter(item => item !== nameError)
    const domainsError = validationErrors.find((obj: any) => {
                      return obj.field === `domains`;
                  });
    validationErrors = validationErrors.filter(item => item !== domainsError)

    const repositoryError = validationErrors.find((obj: any) => {
        return obj.field === `setRepositoryUrl`;
    });
    validationErrors = validationErrors.filter(item => item !== repositoryError)

    useEffect(() => {
        // form for new workspace will not have a workspaceId
        if (workspace) {
            hook.setWorkspace(workspace);
        } else if (workspaceId === 'new') {
            hook.initialiseWorkspace();
        }
    }, [workspace]);

    const onClickDeploy = () => {
        setDeployDisabled(true)
        hook.handleDeploy()
    }

    const { isSubmitDisabled, isDeleteDisabled } = hook.disabledElements;

    const githubAppName = ['production', 'distribution'].includes(AppEnv.APP_ENVIRONMENT) ? "matatika-workspaces" : "matatika-workspaces-staging"

    return (
        <div className={!size.smallScreen ? classes.root_medium : classes.root}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={message != null}
                autoHideDuration={parseInt(AppEnv.APP_SNACKBAR_TIMEOUT, 10)}
                onClose={handleMessageClosed}
                style={{left: '0!important'}}
            >
                <Alert data-testid="saved-message" variant="filled" severity="success">
                    {message}
                </Alert>
            </Snackbar>
            {!!hook.errors.length && hook.errors.map((error: IError, index: number) =>
                <Alert key={index} severity="error">
                    {error.message}
                </Alert>)}
            {!!validationErrors.length && validationErrors.map((error: IValidationError, index: number) =>
                <Alert key={index} severity="error">
                    {error.defaultMessage}
                </Alert>)}
            <ConfirmDialog
                isOpen={hook.isShowDeleteConfirmation}
                title={`Delete '${hook.workspace.name}'?`}
                message="Remove this workspace. This action cannot be undone."
                cancelLabel="Close"
                confirmLabel="Delete"
                handleClose={() => hook.setIsShowDeleteConfirmation(false)}
                handleConfirm={hook.handleDelete} />
            <form onSubmit={hook.handleSubmit} data-testid="workspaceForm">
                <div className="form-row">
                  <h1>{props.title}</h1>
                  {size.smallScreen && 
                  <div title="Help" className={"help-button"} onClick={helpButtonClicked}>
                    <img src={questionMark} alt={questionMark} />
                  </div>
                  }
                </div>
                <hr/>
                <div className="field">
                    <TextField
                        data-testid="name"
                        id="workspaceForm.name"
                        fullWidth
                        required
                        error={!!nameError}
                        helperText={nameError?.defaultMessage || ''}
                        className="pointer"
                        variant="outlined"
                        onChange={hook.onChangeName}
                        name="workspace_name"
                        label="Name"
                        value={hook.name || ''}
                        disabled={hook.workspace == null || hook.workspace.id == null}
                    />
                </div>

                <div className="field">
                    <Autocomplete
                        multiple
                        fullWidth
                        freeSolo
                        autoSelect
                        disabled={hook.workspace == null || hook.workspace.id == null}
                        getOptionLabel={o =>  o.startsWith('@') ? o : `@${o}`}
                        renderInput={params =>
                            <TextField
                                {...params}
                                variant='outlined'
                                label="Approved Domains"
                                placeholder={hook.domains.length ? undefined : "e.g. @company.co.uk"}
                                error={!!domainsError}
                                helperText={domainsError?.defaultMessage}
                            />
                        }
                        onChange={(e, options) => hook.setDomains(options.map(o => o.startsWith('@') ? o.slice(1) : o))}
                        options={[] as string[]}
                        value={hook.domains}
                    />
                </div>
                <div className='field'>
                    <WorkspaceImage workspaceId={workspaceId} workspace={workspace} onChangeImageUrl={hook.onChangeImageUrl} />
                </div>
                {hook.isNew && workspace &&
                    isFeatureEnabled(AppFeatures.WORKSPACE_ADVANCED_CONFIG) && (
                    <CustomAccordion
                        title="Advanced"
                        isLast>
                        {"Deploy an existing workspace or Meltano project"}
                        <TextField
                            data-testid="setRepositoryUrl"
                            id="workspaceForm.setRepositoryUrl"
                            fullWidth
                            variant='outlined'
                            value={hook.repositoryUrl || ''}
                            error={!!repositoryError}
                            helperText={repositoryError?.defaultMessage || ''}
                            placeholder={AppEnv.APP_SERVER_URI?.includes("localhost") ? "file:/path/to/my/project/" : "https://github.com/Matatika/example-github-analytics"}
                            onChange={hook.onSetRepositoryUrl}
                            name="setRepositoryUrl"
                            label="URL" />
                        <TextField
                            id='branch'
                            fullWidth
                            variant='outlined'
                            value={hook.isRemoteGitRepo && hook.repositoryBranch || ""}
                            onChange={hook.onSetRepositoryBranch}
                            label="Branch"
                            disabled={!hook.repositoryUrl || !hook.isRemoteGitRepo}
                            placeholder={hook.isRemoteGitRepo ? hook.defaultBranch : ""}
                            InputLabelProps={{ shrink: hook.isRemoteGitRepo }} />
                        <TextField
                            id='ssh-private-key'
                            fullWidth
                            multiline
                            variant="outlined"
                            value={hook.sshPrivateKey === workspace.sshPrivateKey ? "" : hook.sshPrivateKey}
                            onChange={hook.onSetSshPrivateKey}
                            label="SSH private key"
                            disabled={!hook.isRemoteGitRepo || !hook.repositoryUrl?.startsWith("git@")}
                            helperText="Use with an SSH URL to access repositories" />
                        <TextField
                            id='github-installation-id'
                            fullWidth
                            variant='outlined'
                            value={hook.githubInstallationId || ""}
                            onChange={hook.onSetGithubInstallationId}
                            label="GitHub installation ID"
                            helperText={<>
                                <a href={`https://github.com/apps/${githubAppName}`}
                                    target='_blank'
                                    rel='noreferrer'>
                                    Install the Matatika GitHub App
                                </a>
                                {" and provide the "}
                                <a href={"https://docs.github.com/en/apps/maintaining-github-apps/modifying-a-github-app-registration#navigating-to-your-github-app-settings"}
                                    target='_blank'
                                    rel='noreferrer'>
                                    installation ID
                                </a>
                                {" to access private repositories"}
                            </>}
                            disabled={!hook.repositoryUrl || !hook.isRemoteGitRepo || hook.repostoryUrlHost != "github.com"} />
                    </CustomAccordion>
                )}
                {!hook.isNew && <TextField
                    fullWidth
                    variant='outlined'
                    onChange={e => hook.setDefaultEnvironment(e.target.value)}
                    label="Active environment"
                    placeholder="No environment"
                    value={hook.defaultEnvironment || ''}
                    InputLabelProps={{ shrink: true }} />}
                {!hook.isNew && workspace &&
                    isFeatureEnabled(AppFeatures.WORKSPACE_REPOSITORY) && <>
                  <div className="field">
                      <CopyToClipboard
                          onCopy={copyText}
                          text={workspace.repositoryUrl}
                          data-testid="repositoryUrlCopyButton">
                          <TextField
                              fullWidth
                              className="pointer"
                              variant="outlined"
                              value={workspace.repositoryUrl}
                              name="repositoryUrl"
                              label="Repository URL"
                              data-testid="repositoryUrl"
                              InputProps={ {
                                  readOnly: true,
                                  endAdornment:
                                      <InputAdornment position="end">
                                          <img id="copy-btn" src={copyIcon} width={20} height={20} />
                                      </InputAdornment>
                              }} />
                      </CopyToClipboard>
                  </div>
                  <div className="field">
                      <CopyToClipboard
                          onCopy={copyText}
                          text={workspace.repositoryBranch}>
                          <TextField
                              fullWidth
                              className="pointer"
                              variant="outlined"
                              value={workspace.repositoryBranch}
                              label="Repository branch"
                              InputProps={ {
                                  readOnly: true,
                                  endAdornment:
                                      <InputAdornment position="end">
                                          <img id="copy-btn" src={copyIcon} width={20} height={20} />
                                      </InputAdornment>
                              }} />
                      </CopyToClipboard>
                  </div>
                  <div className="field">
                      <TextField
                          id='ssh-private-key'
                          fullWidth
                          multiline
                          className="pointer"
                          variant="outlined"
                          value={hook.sshPrivateKey === workspace.sshPrivateKey ? "" : hook.sshPrivateKey}
                          placeholder={hook.sshPrivateKey === workspace.sshPrivateKey ? "***" : "No SSH private key"}
                          onChange={hook.onSetSshPrivateKey}
                          label="SSH private key"
                          InputLabelProps={{ shrink: true }} />
                  </div>
                  <div className="field">
                      <CopyToClipboard
                          onCopy={copyText}
                          text={workspace.githubInstallationId}>
                          <TextField
                              fullWidth
                              className="pointer"
                              variant="outlined"
                              value={workspace.githubInstallationId}
                              placeholder="No installation ID"
                              label="GitHub installation ID"
                              InputProps={ {
                                  readOnly: true,
                                  endAdornment:
                                      <InputAdornment position="end">
                                          <img id="copy-btn" src={copyIcon} width={20} height={20} />
                                      </InputAdornment>
                              }}
                              InputLabelProps={{ shrink: true }} />
                      </CopyToClipboard>
                  </div>
                </>}
                {!hook.isNew && workspace && (
                <div className="field" style={{display: "flex"}}>
                    <CopyToClipboard
                        onCopy={copyText}
                        text={workspace.deploymentSecret}
                        data-testid="deploymentSecretCopyButton"
                    >
                        <TextField
                            fullWidth
                            className="pointer"
                            variant="outlined"
                            type="password"
                            value={workspace.deploymentSecret}
                            name="deploymentSecret"
                            label="Deployment Secret"
                            data-testid="deploymentSecret"
                            onChange={() => { workspace.deploymentSecret }}
                            InputProps={ {
                                readOnly: true,
                                endAdornment:
                                    <InputAdornment position="end">
                                        <img id="copy-btn" src={copyIcon} width={20} height={20} />
                                    </InputAdornment>
                            }}
                        />
                    </CopyToClipboard>
                    <div>
                        <Button
                            id="workspace-settings.regenerate-deployment-secret"
                            onClick={hook.handleRegenerateDeploymentSecret}
                            variant='outlined'
                            disabled={hook.isSaving || hook.isDeploying || hook.isDeleting}
                            color='primary'>
                            <Spinner active={hook.isSaving}>
                                Regenerate
                            </Spinner>
                        </Button>
                    </div>
                </div>
                )}
                <div className="form-buttons">
                    <Button
                        id="workspace-settings.submit"
                        onClick={hook.handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={isSubmitDisabled}
                    >
                        <Spinner active={hook.isSaving}>
                            {hook.isNew ? 'Continue' : 'Save'}
                        </Spinner>
                    </Button>
                    {!hook.isNew && <>
                        <Button
                            style={{ marginLeft: 'auto' }}
                            onClick={onClickDeploy}
                            variant='outlined'
                            color="primary"
                            disabled={isDeployDisabled}>
                            <Spinner active={hook.isDeploying}>
                                Deploy
                            </Spinner>
                        </Button>
                        <Button
                            id="workspace-settings.delete"
                            onClick={() => hook.setIsShowDeleteConfirmation(true)}
                            variant="outlined"
                            disabled={isDeleteDisabled}
                            style={{ color: '#ff0000', borderColor: '#ff0000' }}>
                            <Spinner active={hook.isDeleting}>
                                Delete
                            </Spinner>
                        </Button>
                    </>}
                </div>
            </form>
        </div>
    )
};

export default WorkspaceForm;