import * as assert from 'assert-plus'
import { AxiosInstance } from 'axios'
import classNames from 'classnames'
import dateFormat from 'dateformat'
import * as React from 'react'
import { Alert, Button, Col, Form, Toast } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import ContentLoader from 'react-content-loader'
import { withRouter } from 'react-router-dom'
import Workbench from 'src/components/Workbench'
import Back from 'src/components/Back';
import { waitUntilReady, AsyncLoader } from 'src/utils/async'
import { logError } from 'src/utils/Error'
import AppEnv from 'src/utils/appenv'
import ScrollAnimation from 'src/utils/ScrollAnimation'
import validator from 'validator'
import { APIContext } from '../contexts/api'
import { Auth0Context } from '../contexts/auth0'
import { MergedContexts } from '../contexts/merged'
import { WorkspaceContext } from '../contexts/workspace'
import { IInvitationsProps, IInvitationsState } from '../models/views/IInvitation'
import '../styles.css'
import { bindContexts } from 'src/utils/bindContexts'
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { SupervisorAccount, MoreVert } from '@material-ui/icons'
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = () => createStyles({
    largeIcon: {
      width: 30,
      height: 30,
    },
    button: {
        '&:focus': {
            border:0,
        }
    },
});

interface IInvitationsPropsWithStyles extends IInvitationsProps {
  classes: Record<keyof ReturnType<typeof styles>, string>
}

class Invitations extends React.Component<IInvitationsPropsWithStyles, IInvitationsState> implements AsyncLoader {
	static contextType = MergedContexts;

  public isThisMounted = false;
	constructor(props: IInvitationsPropsWithStyles) {
    super(props)
		this.state = {
			ready: false,
			email: { value: '', isValid: true, message: '' },
			isLoading: true,
			submitSuccess: false,
			invitationsList: [],
			message: [],
			inviteActionMessage: ''
		}

		this.submitHandler = this.submitHandler.bind(this)
		this.handleInputChanges = this.handleInputChanges.bind(this)
		this.formIsValid = this.formIsValid.bind(this)
		this.resetValidationStates = this.resetValidationStates.bind(this)
		this.handleCancelInvitation = this.handleCancelInvitation.bind(this)
	}

	// implements AsyncLoader
	public isLoading(): boolean {
		return this.state.isLoading;
	}

	public async componentDidMount() {
		const { catalogAPI } = this.context
		const api = catalogAPI as AxiosInstance
		assert.func(api, 'APIContext must be initialise before this component is used')
		this.props.setViewName && this.props.setViewName("Invitations")

		this.isThisMounted = true
		this.getInvitationsData()
	}

	public componentWillUnmount() {
		this.isThisMounted = false
	}

	private async getInvitationsData() {
		// wait at least MINIMUM_LOAD, to show the placeholder smoothly on each load of this view
		waitUntilReady(
			parseInt(AppEnv.APP_PLACEHOLDER_MINIMUM_LOAD, 10),
			parseInt(AppEnv.APP_PLACEHOLDER_LOAD_TIMEOUT, 10),
			this.isThisMounted,
			this
		).then(() => {
			if (this.isThisMounted) {
				this.setState({ ready: true })
			}
		})
		const { workspace, catalogAPI, setInvitationsList } = this.context
		if (this.isThisMounted && workspace) {
			this.setState({ ready: false })
			await catalogAPI
				.get(`${workspace._links.invitations.href}?size=99`, { errorHandler: false })
				.then((resp: any) => {
					if (this.isThisMounted) {
						if (resp.data._embedded) {

							const invitations = resp.data._embedded.invitations.map((invitation: any) => ({
								...invitation,
								isAdmin: invitation._links && invitation._links.hasOwnProperty('withdraw administrator'),
								isMenuOpen: false
							}))

              this.setState({
								isLoading: false,
								invitationsList: invitations
							})
							setInvitationsList(invitations)
						} else {
							this.setState({ isLoading: false })
						}
					}
				})
				.catch((e: any) => {
					if (this.isThisMounted) {
						this.setState({
							isLoading: false,
							message: ['Error loading invitations. Please try again.']
						})
						logError(e)
					}
				})
		}
	}

	private handleInputChanges = (e: any) => {
		e.preventDefault()
		const name = e.currentTarget.name
		const value = e.currentTarget.value
		const state = {
			...this.state,
			[name]: {
				...this.state[name],
				value
			}
		}

		this.setState(state)
	};

	private async submitHandler(e: any) {
		e.preventDefault()
		// reset states before the validation procedure is run.
		this.resetValidationStates()
		// run the validation, and if it's good move on.

		if (await this.formIsValid()) {
			const { workspace, catalogAPI } = this.context
			// const api = catalogAPI as AxiosInstance;
			const { email } = this.state

			this.setState({
				submitSuccess: false,
				message: [],
				isLoading: true,
				ready: false,
				inviteActionMessage: ''
			})
			const emailList = email.value.split(',')
			const errors: any[] = []
			await Promise.all(
				emailList.map(async (emailObj: any) => {
					const InvitationData = { email: emailObj.trim() }
					await catalogAPI
						.post(workspace._links['create invitation'].href, InvitationData, {
							errorHandler: false
						})
						.catch((error: any) => {
							if (
								error &&
								error.response &&
								error.response.data &&
								error.response.data.message
							) {
								errors.push(error.response.data.message)
							} else if (error && error.response && error.response.message) {
								errors.push(error.response.message)
							} else {
								errors.push('Unexpected error while sending invitation')
							}
						})
				})
			)
			this.setState({ email: { value: '' } })
			if (errors.length > 0) {
				this.setState({ submitSuccess: false, ready: true, message: errors })
			} else {
				this.setState({
					submitSuccess: true,
					ready: true,
					message: ['Invitation sent successfully']
				})
			}
			if (errors.length < emailList.length) {
				await this.getInvitationsData()
			}
		}
		return false
	}

	private async handleCancelInvitation(index: any) {
		const values = [...this.state.invitationsList]
		values[index].loading = true

		this.setState({ invitationsList: values })
		const { catalogAPI } = this.context
		const api = catalogAPI as AxiosInstance
		const invitation = this.state.invitationsList[index]
		let cancelInvitationURI = null
		if (invitation._links && invitation._links['withdraw invitation']) {
			cancelInvitationURI = invitation._links['withdraw invitation'].href
		}
		await api
			.put(cancelInvitationURI)
			.then(() => {
				this.setState({
					inviteActionMessage:
						'Invitation for ' +
						this.state.invitationsList[index].email +
						' was revoked'
				})
				values[index].loading = true
				this.setState({ invitationsList: values })
			})
			.catch(() => {
				this.setState({
					inviteActionMessage: 'Error while withdrawing invitation. Please try again.'
				})
				values[index].loading = false
				this.setState({ invitationsList: values })
			})
		await this.getInvitationsData()
	}

	private async handleResendInvitation(index: any) {
		const values = [...this.state.invitationsList]
		values[index].loading = true

		this.setState({ invitationsList: values })
		const { workspace, catalogAPI } = this.context
		const email = this.state.invitationsList[index].email
		const errors: any[] = []

		if (errors.length === 0) {
			const InvitationData = { email: email.trim() }
			await catalogAPI
				.post(workspace._links['create invitation'].href, InvitationData, {
					errorHandler: false
				})
				.catch((error: any) => {
					if (
						error &&
						error.response &&
						error.response.data &&
						error.response.data.message
					) {
						errors.push(error.response.data.message)
					} else if (error && error.response && error.response.message) {
						errors.push(error.response.message)
					} else {
						errors.push('Unexpected error while resending invitation')
					}
				})
		}
		if (errors.length > 0) {
			this.setState({ ready: true, inviteActionMessage: errors[0] })
		} else {
			this.setState({
				ready: true,
				inviteActionMessage: `Invitation for ${email} was revoked and resent`
			})
		}
		await this.getInvitationsData()
	}

  private async handleDeleteInvitation(index: number) {

    const api = this.context.catalogAPI as AxiosInstance
    const invitations = [...this.state.invitationsList]

    await api.delete(invitations[index]._links['delete invitation'].href)
      .catch(logError)

    await this.getInvitationsData()

  }

	private async handleAddAdministrator(index: number) {

		const api = this.context.catalogAPI as AxiosInstance
		const invitations = [...this.state.invitationsList]

		await api.put(invitations[index]._links['add administrator'].href)
			.catch(logError)

		await this.getInvitationsData()

	}

	private async handleWithdrawAdministrator(index: number) {

		const api = this.context.catalogAPI as AxiosInstance
		const invitations = [...this.state.invitationsList]

		await api.delete(invitations[index]._links['withdraw administrator'].href)
			.catch(logError)

		await this.getInvitationsData()

	}

	formIsValid = async () => {
		const email = { ...this.state.email }
		let isGood = true

		const emailList = email.value.split(',')
		emailList.map(async (emailValue: any) => {
			if (!validator.isEmail(emailValue.trim())) {
				email.isValid = false
				email.message = 'Enter valid email address'
				isGood = false
			}
		})

		// perform addtion validation on password and confirmPassword here...

		if (!isGood) {
			this.setState({
				email
			})
		}

		return isGood
	};

	resetValidationStates = () => {
		const state = JSON.parse(JSON.stringify(this.state))
		Object.keys(state).forEach(key => {
			if (state[key].hasOwnProperty('isValid')) {
				state[key].isValid = true
				state[key].message = ''
			}
		})

		this.setState(state)
	};

	public render() {
		const { email, ready, invitationsList, inviteActionMessage, message } = this.state
		const uniueMessage = Array.from(new Set(message))
		const emailGroupClass = classNames('form-group', { 'has-error': !email.isValid })

		const { workspace } = this.context
		const allowedDomainsMsg = workspace.domains.length
			? `You can only invite users from the following domains (${workspace.domains.map((d: string) => `@${d}`).join(", ")})`
			: ''

		const contentLoader = (
			<Col className={ready ? 'feed-hidden loaderfadeOut' : 'feed'}>
				{/* loaderfadeOut */}
				<div className="dataLoader_parent d-flex" data-testid="loader">
					<Col className="dataLoader pad_0">
						<ContentLoader className="placeholderList w-100" height="25" speed={2}>
							<rect x="0" y="0" rx="0" ry="0" width="150" height="15" />
						</ContentLoader>

						<ContentLoader
							className="placeholderDivider w-100 d-none d-md-block"
							height={10}
							speed={2}
						>
							<rect x="0" y="5" height="2" />
						</ContentLoader>
						<div className="d-none d-md-block clearfix">
							<div className="clearfix col-md-6 float-left pad_0 align-self-center">
								<ContentLoader
									className="col-md-8 float-left pad_0"
									height="25"
									speed={2}
								>
									<rect x="5" y="15" rx="0" ry="0" width="100" height="8" />
								</ContentLoader>
								<ContentLoader
									className="col-md-4 float-left pad_0"
									height="25"
									speed={2}
								>
									<rect x="5" y="15" rx="0" ry="0" width="100" height="8" />
								</ContentLoader>
							</div>
							<ContentLoader
								className="col-md-2 float-left pad_0 align-self-center status"
								height="35"
								speed={2}
							>
								<rect x="5" y="15" rx="0" ry="0" width="100" height="8" />
							</ContentLoader>
						</div>

						<ContentLoader className="placeholderDivider w-100" height={10} speed={2}>
							<rect x="0" y="5" height="2" />
						</ContentLoader>

						<div className="clearfix">
							<div className="clearfix col-8 col-md-6 float-left pad_0 align-self-center">
								<ContentLoader
									className="col-12 col-md-8 float-left pad_0"
									height="25"
									speed={2}
								>
									<rect x="5" y="15" rx="0" ry="0" width="200" height="8" />
								</ContentLoader>
								<ContentLoader
									className="col-12 col-md-4 float-left pad_0"
									height="25"
									speed={2}
								>
									<rect x="5" y="15" rx="0" ry="0" width="150" height="8" />
								</ContentLoader>
							</div>
							<ContentLoader
								className="col-4 col-md-2 float-left pad_0 align-self-center"
								height="26"
								speed={2}
							>
								<rect x="5" y="15" rx="0" ry="0" width="100" height="8" />
							</ContentLoader>
							<ContentLoader
								className="col-12 col-md-4 float-left pad_0 align-self-center"
								height="35"
								speed={2}
							>
								<rect x="5" y="11" rx="0" ry="0" width="130" height="16" />
								<rect x="145" y="11" rx="0" ry="0" width="100" height="16" />
							</ContentLoader>
						</div>

						<ContentLoader className="placeholderDivider w-100" height={10} speed={2}>
							<rect x="0" y="5" height="2" />
						</ContentLoader>

						<div className="clearfix">
							<div className="clearfix col-8 col-md-6 float-left pad_0 align-self-center">
								<ContentLoader
									className="col-12 col-md-8 float-left pad_0"
									height="25"
									speed={2}
								>
									<rect x="5" y="15" rx="0" ry="0" width="200" height="8" />
								</ContentLoader>
								<ContentLoader
									className="col-12 col-md-4 float-left pad_0"
									height="25"
									speed={2}
								>
									<rect x="5" y="15" rx="0" ry="0" width="150" height="8" />
								</ContentLoader>
							</div>
							<ContentLoader
								className="col-4 col-md-2 float-left pad_0 align-self-center"
								height="26"
								speed={2}
							>
								<rect x="5" y="15" rx="0" ry="0" width="100" height="8" />
							</ContentLoader>
							<ContentLoader
								className="col-12 col-md-4 float-left pad_0 align-self-center"
								height="35"
								speed={2}
							>
								<rect x="5" y="11" rx="0" ry="0" width="130" height="16" />
								<rect x="145" y="11" rx="0" ry="0" width="100" height="16" />
							</ContentLoader>
						</div>
					</Col>
				</div>
			</Col>
		)

		const handleToastClose = () => {
			this.setState({ submitSuccess: false, message: [] })
		}
		const handleEmailInput = (e: any) => this.handleInputChanges(e)

		const sendInvitationsDisabled = !workspace._links['create invitation']

		return (
			<Workbench>
				<div className="w-100" data-testid="resolved">
					<div className="full_pageContent clearfix">
					<Back />
						<Toast onClose={handleToastClose} show={this.state.submitSuccess}>
							<Toast.Body>{uniueMessage}</Toast.Body>
						</Toast>
						{this.state.submitSuccess === false &&
							uniueMessage.length > 0 &&
							uniueMessage.map((msg: any, index: any) => (
								<Alert key={index} variant={'warning'}>
									{msg}
								</Alert>
							))}
						<ScrollAnimation animateIn="fadeIn" offset={10}>
							<h1>Invite others to join your workspace</h1>
						</ScrollAnimation>
						<Form onSubmit={this.submitHandler}>
							<ScrollAnimation animateIn="fadeIn" offset={10}>
								<Form.Group
									controlId="exampleForm.domain"
									className={emailGroupClass}
								>
									<Form.Text className="text-muted">
										Enter an email address, or comma separate multiple
										addresses. {allowedDomainsMsg}
									</Form.Text>
									<Form.Text
										className="validation_msg"
										data-testid="email_err"
									>
										{email.message}
									</Form.Text>
									<Form.Control
										data-testid="email"
										as="textarea"
										rows={3}
										onChange={handleEmailInput}
										value={email.value}
										name="email"
									/>
								</Form.Group>
							</ScrollAnimation>
							<Tooltip
								arrow
								title={sendInvitationsDisabled ? AppEnv.APP_DISABLED_FEATURE_DEFAULT_TOOLTIP_TITLE : ""}>
								<span>
									<ScrollAnimation animateIn="fadeIn" offset={10}>
										<Button
											id="submit"
											variant="warning"
											type="submit"
											className="m_top10 float-left"
											disabled={sendInvitationsDisabled || !ready}>
											SEND INVITATIONS
										</Button>
									</ScrollAnimation>
								</span>
							</Tooltip>
							{!ready && (
								<div className="m_top10 d-inline-block float-left spinner_btnHolder">
									<Spinner animation="border" />
								</div>
							)}
						</Form>
					</div>
					<div className="full_pageContent pad_tb15 clearfix">
						{inviteActionMessage && (
							<Alert variant={'warning'}>{inviteActionMessage}</Alert>
						)}
						{ready ? (
							<>
								<ScrollAnimation animateIn="fadeIn" offset={10}>
									<h1>Invitations</h1>
								</ScrollAnimation>
								<ScrollAnimation
									afterAnimatedIn={this.props.afterInvitationsAnimatedIn}
									animateIn="fadeIn"
									offset={10}
								>
									<div className="inviteList" data-testid="inviteList">
										{invitationsList && invitationsList.length > 0
											? (
												<>
													<div className="inviteListRow rowHead  d-none d-md-flex clearfix">
														<div className="clearfix col-md-7 col-lg-8  col-xl-9 float-left pad_0 align-self-center">
															<div className="col-md-9 float-left pad_0">
																Email address
															</div>
															<div className="col-md-3 float-left pad_0">
																Date
															</div>
														</div>
														<div className="col-md-1  col-lg-1  col-xl-1 float-left pad_0 align-self-center status">
															Status
														</div>
														<div className="col-md-4  col-lg-3  col-xl-2 float-left pad_0" />
													</div>
													{invitationsList.map((row: any, index: any) => {

														const handleResend = () => this.handleResendInvitation(index)
														const handleCancel = () => this.handleCancelInvitation(index)
							const handleDelete = () => this.handleDeleteInvitation(index)
														const handleMakeAdmin = () => this.handleAddAdministrator(index)
														const handleRemoveAdmin = () => this.handleWithdrawAdministrator(index)
														const setMenuState = (isOpen: boolean, target?: EventTarget & HTMLButtonElement) => {
															const invitationsList = [...this.state.invitationsList]
															invitationsList[index].isMenuOpen = isOpen
															invitationsList[index].anchorEl = target
															this.setState({ invitationsList: invitationsList })

														}
														const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setMenuState(true, e.currentTarget)
														const handleCloseMenu = () => setMenuState(false)

														return (
															<div
																className="inviteListRow clearfix"
																key={index}
															>
																<div className="clearfix col-8  col-md-7  col-lg-8  col-xl-9 float-left pad_0 align-self-center">
																	<div className="col-12  col-md-9 float-left pad_0 email">
																		{row.email}
																	</div>
																	<div className="col-12  col-md-3 float-left pad_0 date">
																		{dateFormat(
																			row.created,
																			'mmmm dd, yyyy'
																		)}
																	</div>
																</div>
																<div className="col-4  col-md-1  col-lg-1  col-xl-1 float-left pad_0 align-self-center status">
																	<span className={row.status.toLowerCase()}>
																		{row.status}
																	</span>
																</div>
																<div className="col-12  col-md-4  col-lg-3  col-xl-2 float-left pad_0 align-self-center inviteButtons">
																	{row.isAdmin &&
																		<Tooltip
																			title="Administrator"
																			enterDelay={500}>
																			<SupervisorAccount aria-label='admin' />
																		</Tooltip>}
																	<IconButton
									className={this.props.classes.button}
																		aria-label='actions-menu'
																		onClick={handleOpenMenu}>
																		<MoreVert />
																	</IconButton>
																	<Menu
																		open={row.isMenuOpen}
																		anchorEl={row.anchorEl}
																		anchorOrigin={{
																			vertical: 'top',
																			horizontal: 'left',
																		}}
																		transformOrigin={{
																			vertical: 'top',
																			horizontal: 'right',
																		}}
																		onClose={handleCloseMenu}>
																		{row.status !== 'ACCEPTED' ? (
																			<MenuItem
																				dense
																				onClick={handleResend}>
																				Resend
																			</MenuItem>
																		) : (
																			<MenuItem
																				dense
																				onClick={!row.isAdmin ? handleMakeAdmin : handleRemoveAdmin}>
																				{!row.isAdmin ? "Make" : "Remove"} admin
																			</MenuItem>
																		)}
									{row.status !== 'ACCEPTED' && row._links && row._links['delete invitation'] && (
									<MenuItem
										dense
										onClick={handleDelete}>
										Delete
									</MenuItem>
																		)}
																		{row.status !== 'REVOKED' &&
																			<MenuItem
																				dense
																				onClick={handleCancel}>
																				Revoke
																			</MenuItem>}
																	</Menu>
																	{row.loading &&
																		<div className="d-inline-block float-left spinner_btnHolder">
																			<Spinner animation="border" />
																		</div>}
																</div>
															</div>
														)
													})}
												</>
											)
											: (
												<div className="custom_alertbox">
													<div className="alert text-center">
														You have not sent any invitations for this workspace.
													</div>
												</div>
											)}
									</div>
								</ScrollAnimation>
							</>
						) : contentLoader}
					</div>
				</div>
			</Workbench>
		)
	}
}

export const InvitationsWithContext = bindContexts(Invitations, [
	Auth0Context,
	APIContext,
	WorkspaceContext
])
//export default withRouter(InvitationsWithContext)
export default withStyles(styles)(withRouter(InvitationsWithContext));


