import React, { useContext, useState, FunctionComponent, PropsWithChildren, useRef, useEffect } from 'react'
import Moment from 'react-moment'
import { Auth0Context, useAuth0 } from '../contexts/auth0'
import { bindContexts } from '../utils/bindContexts'
import 'src/assets/css/components/jobRow.css'
import calendarIcon from '../assets/images/svgs/calendar.svg';
import scheduleIcon from '../assets/images/svgs/schedule.svg';
import playIcon from '../assets/images/svgs/play.svg';
import deleteIcon from '../assets/images/svgs/delete.svg'
import downloadIcon from '../assets/images/svgs/download-icon.svg'
import { IJobRowProps } from 'src/models/components/IJobRow'
import ndjsonStream from 'src/utils/stream/ndjson-stream'
import stringStream from 'src/utils/stream/string-stream'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { WindowContext } from 'src/contexts/window';
import { logError } from 'src/utils/Error'
import useRowStyles from 'src/assets/css/components/RowComponentsStyles'
import IJob, { JobStatus } from 'src/models/IJob'
import ILog from 'src/models/ILog'


const JobRow: FunctionComponent<IJobRowProps> = (props: PropsWithChildren<IJobRowProps>) => {
  const classes = useRowStyles();
  const { size } = useContext(WindowContext)
  const { job, jobMarker, renderLogs, openLog, handleOpenJobConfirmDialog, downloadLog } = props
  const { getTokenSilently } = useAuth0()
  const [state, setState] = useState({
    isMenuOpen: false,
    menuAnchorEl: null as null | EventTarget & HTMLButtonElement,
  });
  const jobRef = useRef(job)
  const openLogsOnMenuExitRef = useRef(false)

  useEffect(() => {
    jobRef.current = job
  }, [job])

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setState({ ...state, isMenuOpen: true, menuAnchorEl: e.currentTarget })
  const handleCloseMenu = () => setState({ ...state, isMenuOpen: false })

  const getLog = async (prevJob: IJob, sequence?: string) => {
    const job = jobRef.current
    const logsHref = new URL(job._links.logs.href)

    openLog(job)

    if (sequence !== undefined)
      logsHref.searchParams.set('sequence', sequence)

    try {
      const token = await getTokenSilently()

      await fetch(logsHref.toString(), {
        headers: {
          Accept: 'application/stream+json',
          Authorization: `Bearer ${token}`
        }
      })
        .then(async (response) => {
          if (response.status == 200) {
            if (typeof response.body !== 'undefined' && response.body != null) {
              return ndjsonStream(response.body)
            } else {
              const body = await response.text()
              return ndjsonStream(stringStream(body))
            }
          }
          return null
        })
        .then(async (jsonStream: ReadableStream<ILog> | null) => {
          if (!jsonStream)
            return

          const reader = jsonStream.getReader()
          const logs: ILog[] = []

          while (true) {
            const result = await reader.read()

            if (!result.done) {
              logs.push(result.value)
              continue
            }

            const promiseId = ([JobStatus.Queued, JobStatus.Running].includes(job.status) || job.status !== prevJob?.status)
              ? setTimeout(getLog, 5000, job, logs[logs.length - 1]?.sequence)
              : null

            return renderLogs(logs, promiseId)
          }
        })
        .catch(logError)
    } catch (e) {
      logError(e)
    }
  }

  const downloadLogs = () => {
    setState({ ...state, isMenuOpen: false })
    downloadLog(job);
  }

  const showLogs = () => {
    if (!state.isMenuOpen) {
      getLog(job)
      return
    }

    setState({ ...state, isMenuOpen: false })
    openLogsOnMenuExitRef.current = true
  }

  const deleteJob = () => {
    setState({ ...state, isMenuOpen: false })
    handleOpenJobConfirmDialog(job)
  }

  return (
        <div
          id={job.id}
          className={'vertical-center data-job-row'}>
            <div className={'vertical-center info'}>
              <span>
                <img src={calendarIcon} alt="calendar icon" />
                <Moment format="YYYY-MM-DD HH:mm" date={job.created} utc={true} local={true} />
              </span>
              <span>
                <img src={playIcon} alt="play icon" />
                {`by ${job.attempt
                  ? `retry (attempt ${job.attempt}/${job.maxAttempts})`
                  : job.triggeredBy
                  || job._embedded.profile?.email
                  || "schedule"}`}
              </span>
              <span className={`vertical-center status circle ${jobMarker(job.status)}`}>
                {job.status}
              </span>
              <span>
                <img src={scheduleIcon} alt="schedule icon" />
                {job.startTime && job.endTime
                  ? <Moment from={job.startTime}>{job.endTime}</Moment>
                  : <Moment interval={1000} date={job.startTime} utc={true} durationFromNow={true} trim={true} format="h[h]m[m]s[s] [elapsed]" />}
              </span>
            </div>
            <div className="d-flex job-actions">
              {!size.smallScreen ? (
                <>
                  {job._links.logs && <>
                    <span
                      className="logs-button"
                      onClick={showLogs}>
                        View logs
                    </span>
                    <div className="button-wrapper" 
                      style={{ cursor: 'pointer' }}
                      title="Download logs"
                      onClick={downloadLogs}>
                      <img src={downloadIcon} />
                    </div>
                  </>}
                  {job._links['delete job'] &&
                    <div className="button-wrapper"
                      style={{ cursor: 'pointer' }}
                      onClick={deleteJob}
                      title="Delete job history">
                      <img className="delete-job" src={deleteIcon} />
                    </div>}
                </>
              ) : (
                <>
                  <Menu 
                    data-testid="context-menu"
                    className={classes.dropdownMenu}
                    open={state.isMenuOpen}
                    anchorEl={state.menuAnchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={handleCloseMenu}
                    TransitionProps={{
                      onExit: () => {
                        if (openLogsOnMenuExitRef.current)
                          getLog(job)

                        openLogsOnMenuExitRef.current = false
                      }
                    }}>
                    {job._links.logs &&
                      <MenuItem
                        className={classes.dropdownItem}
                        dense
                        onClick={downloadLogs}>
                        <i className='fas fa-download' />
                        <span>Download</span>
                      </MenuItem>}
                    {job._links.logs &&
                      <MenuItem
                        className={classes.dropdownItem}
                        dense
                        onClick={showLogs}>
                        <i className='fas fa-terminal' />
                        <span>Logs</span>
                      </MenuItem>}
                    {job._links['delete job'] &&
                      <MenuItem
                        className={classes.dropdownItem}
                        dense
                        onClick={deleteJob}>
                        <i className='fas fa-trash' />
                        <span>Delete</span>
                      </MenuItem>}
                  </Menu>
                  <IconButton
                    className={classes.button}
                    aria-label='actions-menu'
                    onClick={handleOpenMenu}
                    data-testid="actions-menu"
                    title="More">
                    <MoreVert className={classes.largeIcon} />
                </IconButton>
              </>)}
            </div>
        </div>
  )
}

export const JobRowWithContext = bindContexts(JobRow, [
  Auth0Context,
])

export default JobRow

